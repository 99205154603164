@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Montserrat:wght@400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  overflow-x: hidden;
  position: relative;
  background-color: white !important;
  color: black !important;
  font-family: "Amatic SC", cursive !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

/* ----- NAVBAR STYLING ----- */

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32 ease-in-out;
  background-color: #f8f6f0;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #f8f6f0;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar img {
  width: 70%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  color: #fc7996 !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 35px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link:active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon {
  display: flex;
  margin-left: 14px;
}

.social-icon a {
  display: inline-block;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 50px;
  z-index: 1;
  transition: 0.3s ease-in-out;
  margin-right: 12px;
}

.social-icon a:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

.navbar-text button {
  appearance: none;
  background-color: #ffe1e8;
  border: 3px solid #413f3b;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-family: "Amatic SC", cursive;
  font-size: 25px;
  line-height: normal;
  margin: 0;
  min-height: 50px;
  min-width: 0;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  padding-left: 12px;
  padding-right: 12px;
}

.navbar-text button:disabled {
  pointer-events: none;
}

.navbar-text button:hover {
  color: #fff;
  background-color: #ffafc0;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.navbar-text button:active {
  box-shadow: none;
  transform: translateY(0);
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #443c3c;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #443c3c;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #443c3c;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #443c3c;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #443c3c;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: #443c3c;
}

/* ----- END NAVBAR STYLING ----- */

/* ----- BANNER STYLING ----- */

.banner {
  margin-top: 0px;
  padding: 200px 0 100px 0;
  /* background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; */
}

.banner .tagline {
  letter-spacing: 0.8px;
  padding: 0px 0px;
  font-size: 45px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 75px;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner h2 {
  font-size: 50px;
  color: #fb7c94;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: black;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  appearance: none;
  background-color: #ffe1e8;
  border: 3px solid #413f3b;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: auto;
  font-family: "Amatic SC", cursive;
  font-size: 25px;
  line-height: normal;
  min-height: 50px;
  min-width: 0;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  padding-left: 12px;
  padding-right: 12px;
}

.banner button svg {
  font-size: 25px;
  margin-left: 5px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover {
  background-color: #ffafc0;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.banner button:hover svg {
  margin-left: 30px;
}

.banner img {
  width: 100%;
  top: 50%;
  position: relative;
  display: block;
  transform: translateY(-50%);
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/* ----- END BANNER STYLING ----- */

/* ----- SKILLS STYLING ----- */

.skill {
  position: relative;
  margin-top: 50px;
}

.skill img {
  position: absolute;
  left: 50%;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 110%;
  max-width: 1320px;
  transform: translateX(-50%);
  content: url("./images/skills.png");
}

@media screen and (max-width: 520px) {
  .skill img {
    content: url("./images/skillsmobile.png");
  }
}

.skill .container {
  padding-top: 145px;
}

.icon {
  width: 50%;
  margin: 0 auto 50px auto;
}

.icon svg {
  color: #fc7996;
}

.icon svg:hover {
  transition-duration: 150ms;
  transform: scale(1.1);
  color: #443c3c;
}

/* ----- END SKILLS STYLING ----- */

/* ----- PROJECT CARD STYLING ----- */

.project {
  margin-top: 100px;
}

.folder {
  margin: 50px;
  max-width: 300px;
}

.folder-container {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.folder-container:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: "";
  top: 90%;
  left: 5%;
  height: 20px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(
    center,
    ellipse,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}

.folder-container:hover,
.folder-container:focus,
.folder-container:active {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  /* move the element up */
}

.folder-container:hover:before,
.folder-container:focus:before,
.folder-container:active:before {
  opacity: 1;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  /* move the element down (it will stay in place because it's attached to the element that also moves up) */
}

.card-project {
  border: 8px solid #443c3c;
  border-radius: 16px;
  margin: 20px;
  max-width: 100%;
  min-height: 400px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.info {
  position: relative;
  width: 100%;
  height: 400px;
  background-color: #ffe1e8;
  transform: translateY(100%) translateY(-88px) translateZ(0);
  transition: transform 0.5s ease-out;
}

.info:before {
  z-index: -1;
  display: block;
  position: absolute;
  content: " ";
  width: 100%;
  height: 100%;
  overflow: hidden;
  filter: blur(10px);
  background-size: cover;
  opacity: 0.25;
  transform: translateY(-100%) translateY(88px) translateZ(0);
  transition: transform 0.5s ease-out;
}

.card-project:hover .info,
.card-project:hover .info:before {
  transform: translateY(0) translateZ(0);
}

.title {
  margin: 0px;
  padding: 28px;
  font-size: 30px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
}

.description {
  margin: 0;
  padding: 0 24px 24px;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
}

.project .social-icon a {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* General layout and typography stuff */
/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400");

* {
  box-sizing: border-box;
  position: relative;
  transition: all .3s ease
}

html {
  font-size: 16px
}

body {
  font-family: Open Sans, Verdana, sans-serif;
  color: rgba(0, 0, 0, .87);
  font-weight: 400;
  line-height: 1.45
}

body,
header {
  background: #fafafa
}

header {
  padding: 40px;
  min-height: 200px;
  text-align: center;
  color: rgba(0, 0, 0, .87)
}

header > * {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto
}

header>:last-child {
  margin-bottom: 0
}

h1 {
  margin-bottom: 0.5em;
  font-weight: inherit;
  line-height: 1.2;
  color: #1c5b72;
  font-size: 2.618em
}

@media (min-width:800px) {
  h1 {
    font-size: 4.236em;
    font-weight: 300
  }
}

p {
  margin-bottom: 1.3em;
  line-height: 1.618
}

@media (min-width:800px) {
  p {
    font-size: 1.3em
  }
}

a {
  color: #e03616;
  text-decoration: none
} */

/* ----- END PROJECT CARD STYLING ----- */

/* ----- FOOTER STYLING ----- */

.footer {
  padding: 20px;
  padding-bottom: 0px;
  background-color: #f8f6f0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 150px;
}

.footer img {
  width: 10%;
  position: relative;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
}

.footer p {
  font-weight: 400;
  font-size: 20px;
  color: #2f3127;
  letter-spacing: 0.5px;
}

/* ----- END FOOTER STYLING ----- */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
